<template> 
    <div class="oceanBackground">
        <div class="contentContainer backgroundGradient">
            <div class="innerContentContainer innerContent_justify">
                <div id="BooksAndCds" class="sectionContainer">
                    <div class="headerBox">
                        <h2 class="headerText">{{this.header}}</h2>
                        <h3 class="subHeaderText">{{this.subHeader}}</h3>
                        <p>{{this.headerInfo}}</p>
                    </div>
                    <div class="innerSectionContainer"  v-for="(book, index) in this.bookList" :key="'asset'+index">
                        <div class="imageContainer">
                            <img class="coverImage" :src="require(`../assets/images/${book.imageUrl}`)">
                            <audio
                                v-if="book.audio!==false"
                                preload="auto" 
                                type="audio/mp3" 
                                :src="require(`../assets/audio/${book.audio}`)" 
                                class="audio" 
                                :ref="`${'asset'+index}`"
                                @play="stopOtherTracks(`${'asset'+index}`)"
                                controls></audio>
                            <a v-else class="subCover" target="_blank" :href="book.Link" download="audio">{{book.linkText}}</a>
                        </div>   

                        <div class="textContainer">
                            <h2 class="textTitle">{{book.title}}</h2>
                            <p class="text" v-for="(paragraph, index) in book.paragraphs" :key="'text'+index">
                                {{paragraph.text}}
                            </p>
                            <div class="quoteContainer"  v-for="(quote, index) in book.review" :key="'quote'+index">
                                <p class="quote">{{quote.quote}}</p>
                                <p class="name">{{quote.name}}</p>
                            </div>       
                        </div>    
                    </div>    
                </div>
            </div>
        </div>  
        <Footer></Footer>  
    </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import booksAndCds from '../assets/data/booksAndCds.json'

export default {
  components:{
    Footer
  },
  data: function () {
    return {
        header: booksAndCds.headerText,
        subHeader: booksAndCds.subHeaderText,
        headerInfo: booksAndCds.headerInfo,
        bookList: booksAndCds.bookList,
        currentTrack: null
    }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  methods: {
        stopOtherTracks(newTrack){
            if (this.currentTrack !== newTrack && this.currentTrack!==null) {
                let refName = `${this.currentTrack}`;
                let player = this.$refs[refName][0];
                player.pause();
            }   
            this.currentTrack = newTrack;
        }
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .audio{
      margin-top:16px;
      width:100%;
  }
  .imageContainer{
      display:flex;
      flex-direction:column;
      align-items: center;
      min-width:250px;
      width:36%;
  }
  .innerContent_justify{
    justify-content: center;
    max-width:800px;
  }
  .headerBox{
      display:block; 
      text-align:center;
      max-width:570px;
      margin-bottom:30px;
  }

  .quoteContainer{
      margin-top:15px;
      margin-bottom:20px;
      .quote{
          color:$accent-color;
          font-style: italic;
          font-size: 13px;
      }
      .name{
          margin-top:5px;
          color:$accent-color;
          font-size:14px;
          margin-left:25px;
      }
  }

  .link{
      color:$accent-color;
  }

 .innerSectionContainer{
    border-top:1px solid $accent-color;
 }

 .textTitle{
     color:$accent-color;
 }

.subCover{
    color:$accent-color;
    text-decoration: underline;
}
 

  @media screen and (max-width: $medium-screen) {
     .headerBox{
         margin-top:15%;
     }
     .innerSectionContainer{
         flex-direction:column;
         align-items: center;

         .imageContainer{
             margin-bottom:30px;
             align-items: center;
         }
         .textTitle{
             text-align: center;
         }
     }

     .subCover{
         text-align:center;
     }
     .innerSectionContainer .textContainer{
         margin-left:10px;
     }
      
  }
</style>
