<template> 
  <div class="oceanBackground">
    <div class="contentContainer backgroundGradient">
      <div class="innerContentContainer innerContent_justify">
        <div id="technique" class="technique">
          <h2 class="headerText">{{this.header}}</h2>

          <div class="techniqueInnerContainer" v-for="(technique, index) in this.techniques" :key="index">
              <div class="techniqueBox">
                <img :src="require(`../assets/icons/${technique.icon}`)" class="icon"/>
                <div class="innerTechniqueBox">
                  <h3 class="techniqueTitle">{{technique.header}}</h3>
                  <p class="techniqueText">
                      {{technique.text}}
                      <span v-for="(link, i) in technique.link" :key="i">
                          <router-link  v-bind:class="link.url!==null?'link':''" tag="a" :to="link.url">{{link.text}}</router-link>
                      </span>
                  </p>
                </div>
              </div>
          </div>  
        </div>
      </div>
    </div>  
    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import techniques from '../assets/data/techniques.json'

export default {
  components:{
    Footer
  },
  data: function () {
    return {
        header: techniques.header,
        techniques : techniques.techniques,
    }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
  .innerContent_justify{
    justify-content:flex-start;
  }

  .technique{
    // background-color:pink;
    margin-top:10%;
    padding-left:30px;
    padding-right:30px;
    padding-bottom: 30px;
    display:inline-flex;
    flex-direction:column;
    height:100%;
    overflow:auto;
   
    
  }
   .techniqueInnerContainer{
        .techniqueBox{
            display:flex;
            flex-direction: row;
            margin-top:30px;
            .icon{
              height:60px;
              min-width:60px;
              align-self: center;
              margin-right:15px;
            }
            .innerTechniqueBox{
              display:flex;
              flex-direction: column;
            }
        }
    }

    .techniqueTitle{
        font-family:myriad-semi-bold;
    }
    .techniqueText{
        font-family: myriad-regular;
        text-indent:25px;
    }

  .headerText{
    font-family: myriad-semi-bold;
    font-style: italic;
    font-size:24px;
  }

  .link{
      color:$accent-color;
  }





  @media screen and (max-width: $medium-screen) {
      .technique{
          margin-top:80px;
          padding-left:0px;
          padding-right:0px;
      }
      .techniqueInnerContainer .techniqueBox{
        flex-direction: column;
      }
      .techniqueTitle{
        text-align:center;
      }
  }

  @media screen and (max-width: $mobile-screen){
    .technique{
      margin-top:10%;
    }
    .headerText{
      margin-top:0px;
    }
  }
  
</style>
