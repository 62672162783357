<template> 
  <div class="oceanBackground">
    <div class="contentContainer">
      <h3 id="quoteId"  v-bind:class="this.active === true?'fadeIn':'fadeOut'">{{this.quote}}</h3>  
      <br/>
      <h3 id="quoteNameId"  class="quoteName" v-bind:class=" this.active === true?'quoteName fadeIn':'fadeOut quoteName'">{{this.person}}</h3>
      <!-- quoteNameId should also have class quoteName -->
    </div>  

    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import quotes from '../assets/data/quotes.json'
import {  setInterval } from 'timers';


export default {
  components:{
    Footer
  },
  data: function () {
    return {
        quote: "",
        person: "",
        intervalId:'',
        active:false,
        quoteNumber : 0,
        initial : 0,
        interval: undefined
    }
  },
  methods:{

    recycleFade: function(){
        let quoteList = quotes
        let v = this
          v.active === false?v.active = true:v.active = false
          v.quote = quoteList[v.quoteNumber].quote
          v.person = quoteList[v.quoteNumber].person
      }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  created(){
      this.recycleFade();
      this.interval = setInterval(()=> this.recycleFade(), 2500)
  }, 
   updated(){
     this.initial ++;
     this.active === false?this.quoteNumber === 9? this.quoteNumber=0:this.quoteNumber++:''
   },
  beforeDestroy(){
    clearInterval(this.interval._id)
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    @keyframes fadeIn{
    from {
      opacity: 0;
    }

    to {
      opacity:1;
    }
  }
  .fadeIn{
    color:white;
    width:65%;
    font-family:Artifakt-Bold-ital;
    font-size:30px;
    font-weight:10;
    letter-spacing: 1.5px;
    line-height: 40px;
    animation: fadeIn 2s forwards;
    text-align: center;
    margin-bottom:30px;
  }

  .fadeOut{
    color:white;
    width:65%;
    font-family:Artifakt-Bold-ital;
    font-size:30px;
    font-weight:10;
    letter-spacing: 1.5px;
    text-align:center;
    line-height: 40px;
    animation: fadeOut 2s forwards;
    margin-bottom:30px;
  }
   @keyframes fadeOut{
    from {
      opacity: 1;
    }

    to {
      opacity:0;
    }
  }

  .quoteName{
    text-align: right;
  }

  @media screen and (max-width: $small-screen) {

    .quotes{
      color:black;
      width:60%;
      font-family:Artifakt-ital;
      font-size:16px;
      font-weight:10;
      letter-spacing: 1.5px;
      line-height: 20px;
    }
  }
  
</style>
