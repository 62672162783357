<template>
  <div class="app" id="app">
    <div :class="this.hamburgerOpen ===false?'navBar navBarClosed':'navBar navBarOpen'" id="navBar">
      <div class="siteLogo">
        <div class="logoContainer">
          <h1 class="siteHeader">STEPHANIE R. CARTER, Ph.D.</h1>
          <h3 class="siteSubHeader">Adolescent & Adult Psychology</h3>
        </div>  
        <div v-on:click="toggleBurger()"  class="hamburgerContainer">
          <div :class="this.hamburgerOpen === true?'leftBurger hamburger':'hamburger topBurger'"></div>
          <div :class="this.hamburgerOpen === true?'midBurgerHide':'hamburger'"></div>
          <div :class="this.hamburgerOpen === true?'rightBurger hamburger':'hamburger bottomBurger'"></div>
        </div>
      </div>  
      <ul :class="this.hamburgerOpen===false?'closedMenu nav':'nav'" >
        <li class="mainItem" v-on:click="toggleSubMenu(false)" ><router-link tag="li" to="/">HOME</router-link></li>
        <li class="mainItem" v-on:click="toggleSubMenu(false)"><router-link tag="li" to="/About">ABOUT</router-link></li>
        <li class="mainItem" v-on:click="toggleSubMenu(false)"><router-link tag="li" to="/Services">SERVICES</router-link></li>
        <li class="mainItem sub subDesktop" v-on:click="toggleSubMenu(true)"><router-link tag="li" to="#">APPROACH</router-link>
           <ul :class="this.subMenu === false?'subMenu2':'mobileSub subMenu'">
               <li class="subMenuItem" v-on:click="toggleSubMenu(false)"><router-link to="/Techniques">TECHNIQUES</router-link></li>
            <li class="subMenuItem" v-on:click="toggleSubMenu(false)"><router-link to="/AboutTheBear">ABOUT THE BEAR</router-link></li>
          </ul>
        </li>
        <li class="mainItem" v-on:click="toggleSubMenu(false)"><router-link tag="li" to="/BooksAndCds">BOOKS & CD'S</router-link></li>
        <li class="mainItem" v-on:click="toggleSubMenu(false)"><router-link tag="li" to="/Contact">CONTACT</router-link></li>
      </ul>
    </div> 
    <ul :class="this.subMenu === false?'subMenu2':'deskTopSub subMenu'">
      <li class="subMenuItem" v-on:click="toggleSubMenu(false)"><router-link to="/Techniques">TECHNIQUES</router-link></li>
      <li class="subMenuItem" v-on:click="toggleSubMenu(false)"><router-link to="/AboutTheBear">ABOUT THE BEAR</router-link></li>
    </ul>
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      subMenu:false,
      hamburgerOpen: false
    }
  },
  methods:{
    toggleBurger: function(){
      this.hamburgerOpen === false?
        this.hamburgerOpen = true
      :
        this.hamburgerOpen = false
    },
    toggleSubMenu: function(openSub){
      if(openSub!== true){
        this.hamburgerOpen=false
      }
      this.subMenu === false && openSub === true?
        this.subMenu = true
      :
        this.subMenu = false
    }
  }
}
</script>

<style lang="scss">

  *{
    margin:0px 0px 0px 0px;
    font-family: myriad-regular;
     color:$font-color;
  }
  .siteHeader{
    letter-spacing: 2px;
    font-size:25px;
    color:$headerText;
    font-family: myriad-semi-bold;
  }
  .siteSubHeader{
    font-style: italic;
    letter-spacing: 2.5px;
    font-size:13.5px;
    color:$font-color;
    padding-top:2px;
  }
  .navBar{
    height:80px;
    background-color:white;
    display:flex;
    flex-direction:row;
    position:absolute;
    width:100%;
    border-bottom: $font-color solid .5px;
    z-index:3; 
  }
  .logoContainer{
    flex:1;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left:15px;
  }
  .siteLogo{
    display:flex;
    flex-direction:row;
    flex:1.5;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .nav{
    list-style-type:none;
    flex:2;
    display:flex;
    flex-direction:row;
    justify-content:center;
    
  }
  .mainItem{
    flex:1;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:$font-color
  }

  .router-link-exact-active{
    color:$accent-color;
  }

  .subMenu{
    position:absolute;
    border: $font-color solid .5px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:150px;
    min-width: 90px;
    height:70px;
    padding:0px 0px 0px 0px;
    z-index: 1;
    top:80px;
    left:72vw;
    background-color:white;
  }
  .sub::before{
    content:'';
    width:10px;
    height:10px;
    background-image: url('./assets/icons/arrow.png');
    background-size:contain;
    background-repeat: no-repeat;
    position:relative;
    left:95px;
    top:2px;
  }
  .subDesktop{
    margin-right:30px;
  }
  .subMenu2{
    display:none;
  }
  a{
    color:$font-color;
    text-decoration:none;
  }
  .subMenuItem{
    display: flex;
    justify-content: center;
    align-self: flex-start;
    margin-left:5px;
    margin-bottom:5px;
    cursor: pointer;
    list-style-type: none;
  }

   .hamburger{
    width: 40px;
    height: 5px;
    margin: 6px 0;
    background-color:$accent-color;
    flex:1;
    display: none;
    margin-right:15px;
    margin-left:15px;
    border-radius:2px;
  }

  .hamburgerContainer{
    cursor:pointer;
  }

  .topBurger{
    position:relative;
    animation-name: topBurgerClose;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }


  .bottomBurger{
    position:relative;
    animation-name: bottomBurgerClose;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
  
  @keyframes topBurgerClose {
    from {top:11px;}
    to {top:0px;}
  }
  @keyframes bottomBurgerClose {
    from {bottom:11px;}
    to {bottom:0px;}
  }

  .midBurgerHide{
    display:none;
  }

  .leftBurger{
    position:relative;
    top:6px;
    animation-name: leftBurgerOpen;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }

  .rightBurger{
    position:relative;
    bottom:5px;
    animation-name: rightBurgerOpen;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }

  @keyframes leftBurgerOpen {
    from {transform: rotate(0deg);}
    to {transform: rotate(-40deg);}
  }
  @keyframes rightBurgerOpen {
    from {transform: rotate(0deg);}
    to {transform: rotate(40deg);}
  }

  .navBarClosed{
    height:80px;
  }
  .mobileSub{
      display:none;
    }

  @media screen and (max-width: $medium-screen) {

    .deskTopSub{
      display:none;
    }

    .subMenu{
    position:static;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:none;
    max-width:150px;
  }

  .sub::before{
    left:40px;

  }

  .subMenuItem{
    align-self:center;
  }

  .closedMenu{
    display:none;
  }
  .navBarOpen{
    flex-direction:column;
    height:102%;
  }

  .navBarClosed{
    flex-direction:column;
    height:80px;
  }

  .siteLogo{
    max-height:80px;
  }
  .nav{
    flex-direction:column;
    padding-left:0px;
    padding-top:20px;
    padding-bottom:20px;
  }
  .mainItem{
    font-size:20px;
    justify-content:center;
    align-content: center;
    display:flex;
    flex-direction:column;
    margin-right:0px;
  }
  .sub::before{
    left:70px;
    top:20px;
  }

  .subMenuItem{
    margin-left: 0px;
    padding-top:.5vh;
    font-size:15px;
  }
  .hamburger{
    display:flex;
  }
  
}



 @media screen and (max-width: $mobile-screen) {
   .deskTopSub{
      display:none;
    }
    .siteHeader{
      font-size:16px;
    }
    .siteHeader::after{
    
      font-size:11px;
      bottom:4px;
    }
    .siteSubHeader{
      font-size:10.5px;
    }
    
    .nav{
      padding-top:40px;
      padding-bottom:40px;
    }
    .subMenu{
      left:70vw;
    }
    .subMenuItem{
      font-size:12px;
    }
 }

</style>
