import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Home from './pages/Home';
import About from './pages/About';
import AboutTheBear from './pages/AboutTheBear';
import BooksAndCds from './pages/BooksAndCds';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Techniques from './pages/Techniques';
import browserBlock from './pages/browserBlock';
import './assets/global.css';

Vue.config.productionTip = false
Vue.use(VueRouter);

let isIE = /*@cc_on!@*/false || !!document.documentMode;

const routes = [
  { path:'/', component: Home, name:"Home" },
  { path:'/About', component: About, name:"About" },
  { path:'/Services', component: Services, name:"Services"},
  { path:'/Techniques', component: Techniques, name:"Techniques"},
  { path:'/AboutTheBear', component: AboutTheBear, name: "About the Bear" },
  { path:'/BooksAndCds', component: BooksAndCds, name: "Books and Cds"},
  { path:'/Contact', component: Contact, name: "Contact"}
];

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

isIE===true?
  new Vue({
    render: h => h(browserBlock),
    }).$mount('#app')
:
  new Vue({
    router:router,
    render: h => h(App),
    }).$mount('#app')
