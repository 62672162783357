<template> 
  <div class="oceanBackground">
    <div class="contentContainer backgroundGradient">
        <div class="headerInfo">
            <h2 class="headerItem header">Contact</h2>
            <h2 class="headerItem subHeaderName">Dr. Stephanie R. Carter</h2>
            <a href ="mailto:dr.stephanie.carter@gmail.com" class="headerItem emailLink link">dr.stephanie.carter@gmail.com</a>
        </div>
        <form ref="form" class="form" id="form" action="https://formspree.io/mqkyrwll" method="POST">
            <label class="name" for="fname">Name</label>
            <input class="input" type="text" id="name" name="name">
            <label class="name" for="lname">Email</label>
            <input class="input" type="text" id="email" name="email">
            <label class="name" for="lname">Phone Number</label>
            <input class="input" type="text" id="phoneNumber" name="phoneNumber">
            <label class="name" for="lname">Message</label>
            <textarea name="message" class="input" id="message" for="message" rows = "5" cols = "60"></textarea>
            <div v-on:click="submit" class="submit">Submit</div>
        </form>
    </div>  
    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue';
export default {
  components:{
    Footer
  },
  methods:{
   submit: function(){
       this.$refs.form.submit()
   }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 
  .text{
    font-family: myriad-regular;
    margin-top:15px;
    margin-bottom:15px;
    display: flex;
    text-align: left;
  }
  .headerText{
    margin-bottom:30px;
    font-family: myriad-semi-bold;
    font-style: italic;
    font-size:24px;
    text-align:center;
  }
  .headerItem{
      margin-bottom:15px;
  }
  .headerInfo{
      display:flex;
      flex-direction:column;
      justify-content: center;
      text-align:center;
      width:100%;
      -webkit-flex: 0 1 auto;
      flex-shrink: 0;
  }

  .link{
      color:$accent-color;
  }

  .form{
      display:flex;
      flex-direction:column;
      border-top:solid 1px $accent-color;
      width:$small-screen;
      justify-content: center;
      align-items:center;
      -webkit-flex: 0 1 auto;
      flex-shrink: 0;
  }

  .name{
      margin-top:10px;
      width:500px;
  }

  .input{
      border:solid 2px $accent-color;
      border-radius: 5px;
      width:500px;
  }

  .submit{
      background-color:$accent-color;
      color:white;
      height:25px;
      width:100px;
      margin-top:20px;
      border-radius:5px;
      display:flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  }

  .message{
      height:100px;
  }

  .phoneNumber::before {
    position:relative;
    background-image: url('../assets/icons/phone.png');
    background-size: cover;
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-repeat:no-repeat;
    right:5px;
    top:3px;
    content:"";
}
  .emailLink::before {
    position:relative;
    background-image: url('../assets/icons/email.png');
    background-size: cover;
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-repeat:no-repeat;
    right:6px;
    top:4px;
    content:"";
  }

  @media screen and (max-width: $medium-screen) {
      .header{
          padding-top:80px;
      }
      .name{
          max-width:300px;
      }
      .input{
          max-width:300px;
      }
      .form{
          padding-bottom:10px;
           width:400px;
      }
  }
  
</style>
