<template> 
  <div class="oceanBackground">
    <div class="contentContainer backgroundGradient">
      <div class="innerContentContainer innerContent_justify">
        <div id="bear" class="bear">
          <h2 class="headerText">{{this.header}}</h2>
          <p class="text subHeader">{{this.subHeader}}</p>
          <div class="imageContainer">
            <img src="../assets/images/polarBear2.jpg" class="outerImage"/>
            <img src="../assets/images/polarBear.jpg" class="middleImage"/>
            <img src="../assets/images/polarBear3.jpg" class="outerImage"/>
          </div>
          <div class="textContainer" v-for="(paragraph, index) in this.paragraphs" :key="index">
            <p :class="paragraph.bold===true?'boldText':'text'">
            {{paragraph.text}}
            </p>
            <ul v-bind:class="paragraph.list.length>0?'list':''">
              <li class="listItem" v-for="(listItem, index) in paragraph.list" :key="index">
                {{listItem.sense}}
              </li>
            </ul>

            <div :class="paragraph.icon===true?'bearPawIcon':''"></div> 
          </div>
        </div>
      </div>
    </div>  
    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import aboutTheBear from '../assets/data/aboutTheBear.json'

export default {
  components:{
    Footer
  },
  data: function () {
    return {
        header: aboutTheBear.header,
        subHeader: aboutTheBear.subHeader,
        paragraphs : aboutTheBear.paragraphs,
        closing : aboutTheBear.end
    }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
  .innerContent_justify{
    justify-content: center;
    max-width:800px;
  }
  .bear{
    margin-top:10%;
    padding-left:30px;
    padding-right:30px;
    padding-bottom: 30px;
    display:inline-flex;
    flex-direction:column;
    height:100%;
    overflow:auto;
   
    
  }
   .techniqueInnerContainer{
        .techniqueBox{
            margin-top:30px;
            .techniqueTitle{
                font-family:myriad-semi-bold;
            }
            
        }
    }

  .textContainer{
    display:inline;
    flex-direction: column;
    margin-top:15px;
  }

  .subHeader{
    margin-left:30px;
  }

  .text{
    font-family: myriad-regular;
    
    margin-bottom:15px;
    display: flex;
    text-align: left;
    // background-color:orange;
  }

  .boldText{
    font-family: myriad-semi-bold;
    margin-top:15px;
    margin-bottom:15px;
    display: flex;
    text-align: left;
  }
  .headerText{
    margin-bottom:30px;
    font-family: myriad-semi-bold;
    font-style: italic;
    font-size:24px;
    text-align:center;
  }

  .link{
      color:$accent-color;
  }

  .imageContainer{
    display:inline;
    height:200px;
    justify-content: center;
    align-items: center;
    margin:auto auto;
    padding-top:40px;
    padding-bottom:40px;
    .outerImage{
      height:130px;
      width:175px;
      background-color:black;
    }
    .middleImage{
      height:175px;
      width:225px;
      margin-left:20px;
      margin-right:20px;
      background-color:black;
    }
  }
  .list{
    margin-bottom:40px;
  }
  
  .listItem{
    margin-top:20px;
  }
  
  .bearPawIcon{
    background-image: url('../assets/icons/bearPaw.png');
    background-size: contain;
    height:80px;
    width:80px;
    display: block;
    margin:auto auto;
    align-self: center;
    margin-top:30px;
    margin-bottom:30px;
  }

  @media screen and (max-width: $medium-screen) {
      .technique{
          margin-top:80px;
          padding-left:0px;
          padding-right:0px;
      }
      .imageContainer{
        padding-top:50px;
        padding-bottom:50px;
        margin:auto auto;
        .middleImage{
          margin-left:5px;
          margin-right:5px;
        }
        .outerImage{
          display:none;
        }
      }
  
    @media screen and (max-width: $mobile-screen){
      .bear{
          padding-left:0px;
          padding-right:0px;
          padding-bottom: 0px;
      }
    }
  }
  
</style>
