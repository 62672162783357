<template> 
  <div class="oceanBackground">
      <div class="innerContainer">
            <h2 class="downloadText">Please Download a Modern Browser to view this site.</h2>
            <ul>
                <li class="browserList" v-for="(browser, index) in this.browsers" :key="index">
                    <a :href="browser.link">
                        <img class="browserImage" :src="require(`../assets/images/${browser.image}`)">
                    </a>
                </li>    
            </ul>    
      </div>
  </div>
</template>

<script>
import browserData from '../assets/data/browserBlock.json'
export default {
  data: function () {
    return {
        browsers: browserData.browsers
    }
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .innerContainer{
      margin:auto auto;
    //   background-color:red;
      width:75%;
      text-align:center;
      padding-top:20%;
  }
  .downloadText{
      color:white;
      font-size:30px;
  }
  .browserList{
      display:inline-block;
  }
  .browserImage{
      margin-left:30px;
      margin-right:30px;
      margin-top:30px;
      height:100px;
      width:100px;
  }
</style>
