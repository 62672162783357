<template> 
    <div class="footerContainer">
      <div class="footerMenu">
        <h2 class="footerHeader">SITE PAGES</h2>
        <ul class="menu">
            <router-link class="footerText menuItem" tag="li" to="/">HOME</router-link>
            <router-link class ="footerText menuItem" tag="li" to="/About">ABOUT</router-link>
            <router-link class="footerText menuItem" tag="li" to="/Services">SERVICES</router-link>
            <router-link class="footerText menuItem" tag="li" to="/Techniques">TECHNIQUES</router-link>
            <router-link class="footerText menuItem" tag="li" to="/AboutTheBear">ABOUT THE BEAR</router-link>
            <router-link class="footerText menuItem" tag="li" to="/BooksAndCds">BOOKS & CD'S</router-link>
            <router-link class="footerText menuItem" tag="li" to="/Contact">CONTACT</router-link>
        </ul>
      </div>  

      <div class="footer">
        <div class="leftFooterContainer">
          <h3 class="footerText">MIAMI & SOUTH FLORIDA</h3>
          <h3 class="footerText">DR.STEPHANIE.CARTER@GMAIL.COM</h3>
        </div>

        <div class="rightFooterContainer">
          <h3 class="footerText">COPYRIGHT 2020 | ALL RIGHTS RESERVED</h3>
          <h3 class="footerText">STEPHANIE R. CARTER, PH.D., PA</h3>
          <h3 class="footerText">WEBSITE DEVELOPMENT BY AARON SILVERSTEIN</h3>
        </div>  
      </div>
    </div>  
</template>

<script>


export default {

  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .footerHeader{
    color:white;
    font-family: myriad-semi-bold;
    display:flex;
    justify-content: center;
    margin-top:20px;
    font-size:18px;
    display: none;
  }
  .footerContainer{
    display:flex;
    flex-direction:column;
    background-color:$accent-color;
  }
  .menu{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: flex-start;
    padding-top:30px;
    padding-bottom:30px;
    margin-left:15px;
    margin-right:15px;
    border-bottom:white solid 1px;
  }
 .second{
   padding-left:20px;
 }

  ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .menuItem{
    line-height:50px;
    flex:1;
    list-style-type: none;
    text-align: center;
  }
  li:hover{
    cursor: pointer;
  }
  .footer{
    height:12vh;
    display:flex;
    flex-direction:row;
    overflow: hidden;
    .leftFooterContainer{
      display:flex;
      flex-direction: column;
      justify-content: center;
      flex:1;
      margin-left:15px;
    }
    .rightFooterContainer{
      display:flex;
      flex-direction:column;
      justify-content: center;
      flex:1;
      align-items: flex-end;
      margin-right:15px;
    }
  }
  .footerText{
        color:white;
        font-size:11px;
        line-height: 20px;
        font-family:Artifakt-Regular;
    }
  @media screen and (max-width: $small-screen) {
      .menu{
        padding:10px 0px 10px 0px;
        flex-direction: column;
        align-items:flex-start;
        border-bottom: none;
      }

      .footerHeader{
        justify-content: flex-start;
        margin-left:15px;
        display: flex;
      }
      .footer{
          flex-direction:column;
          height:15vh;
          .rightFooterContainer{
              align-items: flex-start;
              margin-left:15px;
              margin-right:15px;
          }
          .footerText{
            line-height: 12px;
            font-size:9px;
          }
      }
  }
  
</style>
