<template> 
  <div class="oceanBackground">
    <div class="contentContainer backgroundGradient">
      <div class="innerContentContainer innerContent_justify">
        <div id="service" class="service">
          <h2 class="headerText">{{this.header}}</h2>

          <div class="iconContainer">
            <div class="icon telehealth"></div>
          </div>  

          <h3 class="preServiceText serviceText">{{this.preText}}</h3>

          <div class="serviceListContainer">
            <ul class="serviceList" v-for="(list, index) in this.serviceList" :key="index">
                <li class="serviceItem" v-for="(item, i) in list.list" :key="i">{{item}}</li>
            </ul> 
          </div>   
          <p class="serviceText" v-for="(paragraph, index) in this.text" :key="index">
            {{paragraph.text}}
            <span v-for="(link, i) in paragraph.link" :key="i">
              <router-link  v-bind:class="link.url!==null?'link':''" tag="a" :to="link.url">{{link.text}}</router-link>
            </span>
          </p>  
        </div>
      </div>
    </div>  
    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import services from '../assets/data/services.json'

export default {
  components:{
    Footer
  },
  data: function () {
    return {
        header: services.header,
        preText: services.preText,
        serviceList: services.serviceList,
        text: services.paragraph
    }
  },
  mounted(){
    window.scrollTo(0, 0);
  },
  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .innerContent_justify{
    justify-content: flex-start;
    max-width:845px;
  }
  .service{
    margin-top:10%;
    padding-left:30px;
    padding-right:30px;
    padding-bottom: 30px;
    display:inline-flex;
    flex-direction:column;
    height:100%;
    overflow:auto;
    .serviceText{
      margin-top:20px;
      font-family: myriad-regular;
    }
  }

  .headerText{
    font-family: myriad-semi-bold;
    font-size:24px;
  }

  .serviceListContainer{
      display:flex;
      flex-direction:row;
      margin-top:20px;
      justify-content: center;
      min-height:180px;
  }
  .serviceList{
      list-style-type: circle;
      padding-left: 15px;
      margin-right:30px;
  }
  .serviceItem{
      margin-top:10px;
  }

  .iconContainer{
    display:flex;
    flex-direction:row;
    align-items: center;
    height:200px;
    min-height:200px;
    justify-content: center;
    
  }
  .icon{
    height:150px;
    width:150px;
    margin-left:30px;
    margin-right:30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .telehealth{

      background-image:url('../assets/icons/telehealth.png');
      background-size: contain;
    }

  .preServiceText{
    text-align: center;
  }

  .link{
      color:$accent-color;
  }

  @media screen and (max-width: $medium-screen) {
      .serviceContainer{
        width:75%;
      }
      .service{
          margin-top:70px;
          padding-left:0px;
          padding-right:0px;
      }
      .serviceList{
          margin-right:5%;
      }
      .serviceItem{
          font-size:12px;
      }
  }
  @media screen and (max-width: $mobile-screen) {
    .serviceListContainer{
      margin-bottom:70px;
    }
    .service{
      margin-top:50px;
    }
  }
  
</style>
