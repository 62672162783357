import { render, staticRenderFns } from "./AboutTheBear.vue?vue&type=template&id=c3f1f598&scoped=true"
import script from "./AboutTheBear.vue?vue&type=script&lang=js"
export * from "./AboutTheBear.vue?vue&type=script&lang=js"
import style0 from "./AboutTheBear.vue?vue&type=style&index=0&id=c3f1f598&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3f1f598",
  null
  
)

export default component.exports