<template> 
  <div class="oceanBackground">
    <div class="contentContainer backgroundGradient">
      <div class="innerContentContainer innerContent_justify">
        <div id="aboutContainer" class="sectionContainer">
          <div class="innerSectionContainer">
            <div class="imageContainer">
                <img class="coverImage" src='../assets/images/bio.jpeg'>
                <img class="subCover" src='../assets/images/FPA.png'>
            </div>
            <div id="bio" class="textContainer">
              <h2 class="headerText">
                Dr. Stephanie R. Carter is a licensed psychologist who has been serving a multi-cultural clientele for over 30 years.
              </h2>
              <p class="text" v-for="(paragraph, index) in this.text" :key="index">
                {{paragraph.text}}
                <span v-for="(link, i) in paragraph.link" :key="i">
                  <router-link  v-bind:class="link.url!==null?'link':''" tag="a" :to="link.url">{{link.text}}</router-link>
                </span>
              </p>  
            </div>
          </div>
        </div>
      </div>
    </div>  
    <Footer></Footer>  

  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import text from '../assets/data/bio.json'

export default {
  components:{
    Footer
  },
  data: function () {
    return {
        text: text,
        safari: false
    }
  },
  beforeMount(){
   
  },
  mounted() {
    window.scrollTo(0, 0);
    
    let userAgentString = navigator.userAgent
    let safariAgent = userAgentString.indexOf("Safari") > -1; 
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;

    if (chromeAgent === false && safariAgent === true) this.safari = true

    this.text = text

  },

  name: 'App'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  p.text{
    display: inline-block;
  }
  .innerContent_justify{
    justify-content: center;
    max-width:800px
  }
  
  .headerText{
    font-family: myriad-semi-bold;
    font-style: italic;
    font-size:24px;
    text-align:left;
  }

  .quoteContainer{
      margin-top:15px;
      margin-bottom:20px;
      .quote{
          color:$accent-color;
          font-style: italic;
          font-size: 13px;
      }
      .name{
          margin-top:5px;
          color:$accent-color;
          font-size:14px;
          margin-left:25px;
      }
  }

  .subCover{
    max-width:200px;
  }

  .link{
      color:$accent-color;
  }


 
  @media screen and (max-width: $medium-screen) {
     .headerBox{
         margin-top:15%;
     }
     .innerSectionContainer{
         flex-direction:column;
         align-items: center;

         .imageContainer{
             margin-bottom:30px;
         }
         .imageContainer{
             align-items: center;
         }
         .headerText{
             text-align: center;
         }
     }
     
     .subCover{
         margin:auto;
     }
     .innerSectionContainer .textContainer{
         margin-left:10px;
     }
      
  }
  
  @media screen and (max-width: $mobile-screen){
    .coverImage{
      display:block;
      margin:auto;
      height: auto;
      width: 250px;
    }
    .subCover{
      width:100px;
      height:auto;
      margin-top:30px;
    }
  }

  
</style>
